import React from "react";
import {
  FaHome,
  FaBlenderPhone,
  FaMailBulk,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";

export const ContactUsCard = () => {
  return (
    <div className="bg-[#FFEDE2] p-6">
      <div className="text-4xl tracking-widest text-center my-6 font-bold mb-10">
        Reach out to us{" "}
      </div>
      <div className="flex flex-wrap items-center justify-center gap-32 md:w-full mb-5 sm:w-full">
        <div className="p-2 transition-all duration-300 transform border-2 border-teal-300 shadow-sm rounded-xl w-80 h-48">
          <a className="flex justify-center mt-3" target="_blank">
            <FaHome className="text-3xl" />
          </a>
          <h2 className="flex justify-center mb-2 text-lg md:mt-5">Current Address:</h2>
          <p className="text-sm text-center text-gray-500 md:mt-3">
            A1, Second Floor, Sector 59, Noida UP, Pando India Software Consultants.
          </p>
        </div>
      </div>
    </div>
  );
};
