import React from "react";
import Cashiq from "../../assets/logo/HomeCardCashiq.svg";
import Playstore from "../../assets/playstoreg.png";
import easydocuments from "../../assets/easydoc.png";
import StudentCorner from "../../assets/logo/HomeCardStudent.svg";

import './index.css';
import "@fontsource/poppins";


export const Launching = () => {
  return (
    <div className="flex flex-col items-center min-h-full py-12 m-4 bg-gray-50 place-items-center">
      {/* Title Section */}
      <h1 className="mb-4 text-3xl font-bold text-center">Explore Our Digital Products</h1>
      <p className="max-w-2xl mb-12 text-center text-gray-600">
        Enhance your digital experience with our innovative apps and websites—explore and connect
        today.
      </p>


      {/* Product Cards Section */}
      <div className="grid justify-center grid-cols-1 h-[58vh] gap-8 m-3 md:grid-cols-2 lg:grid-cols-3 place-items-center md:m-8 ">
        {/* First Card */}
        <div className="flex flex-col items-center w-full h-full p-6 bg-blue-100 shadow-lg rounded-xl">
          <div className="bg-[#324DDD] h-36 w-full mb-4 rounded-xl flex justify-center items-center">
            <img src={StudentCorner} className="w-56 " alt="Student Corner" />
          </div>
          <h3 className="mb-2 text-lg font-semibold text-center">
            Enhance your digital experience day
          </h3>
          <p className="mb-4 text-center text-gray-600">
            Enhance your digital experience with our innovative apps and websites—explore and
            connect today.
          </p>
          <div className="flex justify-between w-full mt-auto space-x-2">
            <button className="flex items-center px-4 py-2 text-white bg-black rounded-xl">
              <a href="https://play.google.com/store/apps/details?id=com.pando.app.studentcornerkotlin&pcampaignid=web_share">
                <img src={Playstore} alt="Google Play" className="mr-2 md:w-48 sm:w-36" />
              </a>
            </button>
            <a href="https://studentscorners.com/">
              <button className="bg-[#324DDD] md:px-5 md:py-7 p-3  text-white px-4 py-2 rounded-lg">
                Visit Website
              </button>
            </a>
          </div>
        </div>

        {/* Second Card */}
        <div className="flex flex-col items-center w-full h-full p-6 bg-teal-100 shadow-lg rounded-xl ">
          <div className="bg-[#14b2b8] h-36 w-full mb-4 rounded-xl flex justify-center items-center">
            <img src={easydocuments} className="w-20 h-20 " alt="EasyDoc" />
          </div>
          <h3 className="mb-2 text-lg font-semibold text-center">
            Enhance your digital experience day
          </h3>
          <p className="mb-4 text-center text-gray-600">
            Enhance your digital experience with our innovative apps and websites—explore and
            connect today.
          </p>

          <div className="flex justify-between w-full mt-auto space-x-2">
            <div className="">
              <a href="https://play.google.com/store/apps/details?id=com.pando.easydocuments&pcampaignid=web_share">
                <button className="flex items-center bg-black text-white px-4  md:py-8 xl:py-3 py-[7px] lg:py-9 rounded-xl easy">
                  <img src={Playstore} alt="Google Play" className="mr-2 md:w-48 sm:w-36" />
                </button>
              </a>
            </div>
            <a href="https://easydocoments.com/">
              <button className="bg-[#14b2b8] text-white md:px-5 md:py-7 p-3 rounded-lg">
                Visit Website
              </button>
            </a>
          </div>
        </div>
        {/* Third Card */}
        <div className="flex flex-col items-center w-full h-full p-6 bg-blue-200 shadow-lg rounded-xl ">
          <div className="bg-[#293394] h-36 w-full mb-4 rounded-xl flex justify-center items-center">
            <img src={Cashiq} className="h-[50vh] w-[50vw]" alt="EasyDoc" />
          </div>
          <h3 className="mb-2 text-lg font-semibold text-center">
            Enhance your digital experience day
          </h3>
          <p className="mb-4 text-center text-gray-600">
            Enhance your digital experience with our innovative apps and websites—explore and
            connect today.
          </p>

          <div className="flex justify-center w-full mt-auto space-x-2">
            
            <a href="https://icashiq.com/">
              <button className="bg-[#293394] text-white md:px-5 md:py-7 rounded-lg h-[10vh] ">
                Visit Website
              </button>
            </a>
          </div>
        </div>

        {/*Third Card (Coming Soon) */}

        {/* <div className="flex flex-col items-center w-full p-6 bg-orange-100 shadow-lg rounded-xl ">
          <div className="lg:h-[350px] md:h-[252px] h-[250px] w-full mb-4 rounded-xl flex justify-center items-center">
            <img src={Alarm} className="h-24 w-28" alt="" />
          </div>
          <h3 className="mb-2 text-lg font-semibold text-center">Coming Soon</h3>
          <p className="mb-4 text-center text-gray-600">Stay Tuned!</p>
          <div className="flex justify-between w-full mt-auto space-x-2"></div>
        </div> */}
      </div>
    </div>
  );
};
