import React, { useState } from "react";
import student from "../../assets/logo/Student.svg";
import easydocuments from "../../assets/Group 76.svg";
import xbinlegal from "../../assets/Group 74.svg";
import yoursArticle from "../../assets/logo/Group 171.svg";
import cashiq from "../../assets/logo/Cash IQ.svg";
import more from "../../assets/clock.png";



// Remove info from productLinks since it will not be a link
const productLinks = {
  student: "https://studentscorners.com/",
  pdf: "https://easydocoments.com/",
  xbinlegal: "https://xbinlegal.pandoconsultants.com/",

  yoursArticle: "https://yoursarticle.com/",

  // yourArticle: "https://yoursarticle.com/",

  cashiq: "https://icashiq.com/",
};

export const Products = ({ toggle }: { toggle: () => void }) => {
  const [hovered, setHovered] = useState<string | null>(null);

  const hoverStyle = {
    color: "black",
    textDecoration: "underline",
  };

  const defaultStyle = {
    color: "#26C0B6",
    textDecoration: "none",
  };

  const imageSize = {
    width: "100%",
    height: "auto",
    maxWidth: "110px",
    maxHeight: "110px",
  };

  
  return (
    <div className="absolute left-0 top-[80px] w-full h-auto overflow-x-auto">
      <div className="w-full relative bg-[#FFFFFF] text-center">
        <div className="w-10/12 mx-auto">
          <ul className="flex flex-wrap justify-start pb-10 text-lg list-none">
            {Object.entries(productLinks).map(([key, url], index) => (
              <li
                key={key}
                className="flex items-start w-full my-4 sm:w-1/2 lg:w-1/3 text-start"
                onMouseEnter={() => setHovered(key)}
                onMouseLeave={() => setHovered(null)}
                style={{
                  position: "relative",
                  overflow: "hidden",
                  alignItems: "center",
                }}
              >
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center"
                >
                  <div
                    className="relative flex items-center justify-center overflow-hidden"
                    style={imageSize}
                  >
                    <img
                      src={
                           key === "student"
                          ? student
                          : key === "pdf"
                          ? easydocuments
                          : key === "xbinlegal"
                          ? xbinlegal
                          : key === "yoursArticle"
                          ? yoursArticle
                          : key === "cashiq"
                          ? cashiq
                          : yoursArticle
                      }
                      alt={key}
                      className="object-contain w-full h-full"
                    />
                  </div>
                  <div className="flex flex-col ml-4">
                    <p
                      className="pt-1 text-xl font-semibold"
                      style={hovered === key ? hoverStyle : defaultStyle}
                    >
                      {key === "student"
                        ? "Student's Corners"
                        : key === "pdf"
                        ? "Easy Docoments"
                        : key === "xbinlegal"
                        ? "Xbinlegal"
                        : key === "yoursArticle"
                        ? "Yours Article"
                        : key === "cashiq"
                        ? "iCash IQ"

                        : ""}
                    </p>
                    {key === "student" && (
                      <p className="pt-1 text-base text-black">Gain best knowledge here</p>
                    )}
                    {key === "pdf" && (
                      <p className="pt-1 text-base mr-2 text-black">
                        Your one step Solution for all Documents Need
                      </p>
                    )}
                    {key === "xbinlegal" && (
                      <p className="pt-1 text-base mr-2 text-black">
                        Empowering Start-ups with hassle free Solution
                      </p>
                    )}

                    {key === "yoursArticle" && (
                      <p className="pt-1 mr-2 text-base text-black">
                        Dive into insightful blogs, participate in, engaging surveys, enhance your
                        skills.
                      </p>
                    )} 
                    {key === "cashiq" && (
                      <p className="pt-1 text-base text-black">
                        Your Gateway to Global Surveys and Rewards
                        {/* Empowering your opinions by providing access to multiple survey platforms, ensuring your efforts are rewarded every step of the way */}
                      </p>
                    )}
                    
                  </div>
                </a>
              </li>
            ))}
            <li
              className="flex items-start w-full my-4 sm:w-1/2 lg:w-1/3 text-start"
              onMouseEnter={() => setHovered("person")}
              onMouseLeave={() => setHovered(null)}
              style={{ position: "relative", overflow: "hidden" }}
            >
              <div className="w-[80px] h-[80px] flex items-center justify-center overflow-hidden relative">
                <img src={more} alt="more" className="object-contain w-full h-full" />
              </div>
              <p
                className="pt-1 ml-4 text-xl font-semibold"
                style={hovered === "person" ? hoverStyle : defaultStyle}
              >
                Coming soon....
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

